import React from 'react'
import styled from 'styled-components'
import { Media } from '../theme'

const Tip = styled.div`
  width: 100%;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fd5c1f;
  background: #ffded2;
  text-align: center;
  position: fixed;
  top: 0;
  z-index: 4800;
  ${Media.phone`
      font-size: 12px;
      height: 60px;
      span {
        width: 6.7rem;
      }
    `}
`

const TopNotice = ({ onClose }) => (
  <Tip>
    <span>瑞云科技授权深圳市云启数智科技作为本司特约授权合作伙伴，为镭速传输提供专业服务和运营支撑</span>
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='20'
      height='20'
      viewBox='0 0 20 20'
      onClick={onClose}
      style={{ cursor: 'pointer' }}
    >
      <g clipPath='url(#clip-path)'>
        <path
          fill='#FD5C1F'
          d='M6.1876,5.48L14.5209,13.8133Q14.5912,13.8836,14.6293,13.9755Q14.6673,14.0674,14.6673,14.1668Q14.6673,14.2161,14.6577,14.2644Q14.6481,14.3127,14.6293,14.3582Q14.6104,14.4037,14.5831,14.4446Q14.5557,14.4856,14.5209,14.5204Q14.486,14.5552,14.4451,14.5826Q14.4042,14.6099,14.3587,14.6288Q14.3132,14.6476,14.2649,14.6572Q14.2166,14.6668,14.1673,14.6668Q14.0679,14.6668,13.976,14.6288Q13.8841,14.5907,13.8138,14.5204L5.4804,6.187Q5.4101,6.1167,5.372,6.0248Q5.334,5.933,5.334,5.8335Q5.334,5.7843,5.3436,5.736Q5.3532,5.6877,5.372,5.6422Q5.3909,5.5967,5.4182,5.5557Q5.4456,5.5148,5.4804,5.4799Q5.5153,5.4451,5.5562,5.4178Q5.5971,5.3904,5.6426,5.3716Q5.6881,5.3527,5.7364,5.3431Q5.7847,5.3335,5.834,5.3335Q5.9334,5.3335,6.0253,5.3716Q6.1172,5.4096,6.1875,5.4799Z'
        />
        <path
          fill='#FD5C1F'
          d='M14.5209,6.187Q14.5912,6.1167,14.6293,6.0248Q14.6673,5.933,14.6673,5.8335Q14.6673,5.7843,14.6577,5.736Q14.6481,5.6877,14.6293,5.6422Q14.6104,5.5967,14.5831,5.5557Q14.5557,5.5148,14.5209,5.4799Q14.486,5.4451,14.4451,5.4178Q14.4042,5.3904,14.3587,5.3716Q14.3132,5.3527,14.2649,5.3431Q14.2166,5.3335,14.1673,5.3335Q14.0679,5.3335,13.976,5.3716Q13.8841,5.4096,13.8138,5.4799L5.4806,13.8131Q5.4101,13.8836,5.372,13.9755Q5.334,14.0674,5.334,14.1668Q5.334,14.2161,5.3436,14.2644Q5.3532,14.3127,5.372,14.3582Q5.3909,14.4037,5.4182,14.4446Q5.4456,14.4856,5.4804,14.5204Q5.5153,14.5552,5.5562,14.5826Q5.5971,14.6099,5.6426,14.6288Q5.6881,14.6476,5.7364,14.6572Q5.7847,14.6668,5.834,14.6668Q5.9334,14.6668,6.0253,14.6288Q6.1172,14.5907,6.1875,14.5204Z'
        />
      </g>
    </svg>
  </Tip>
)

export default TopNotice
