import React from 'react'
import styled from 'styled-components'
import SolutionBanner from './index'
import { Media } from '@raysync/common/theme'
import HomeFerryImg from './ferry-background.png'
import CollaborationImg from './collaboration-bg.png'
const Wrap = styled.div`
  > div {
    margin-top: ${p => (p.isHome ? '60px' : 'unset')};
    height: ${p => p.height + 'px'};
    img {
      height: ${p => p.height + 'px'};
    }
  }
  .solution-banner {
    background-position: left 0px bottom !important;
    background-size: 130% 138% !important;
  }
  ${Media.phone`
    > div {
      height: 10.4rem;
      margin-top: ${p => (p.isHome ? '0.9rem' : 'unset')};
      padding-top: .4rem;
    }
    .ftp-wrap {
      background-size: 28.35rem 12.9rem !important;
      background-position: calc(100% + 10.5rem) calc(100% + 2.4rem) !important;
    }
    .ferry-wrap {
      background-size: 28.35rem 12.9rem !important;
      background-position: calc(100% + 10.5rem) calc(100% + 2.4rem) !important;
    }
    .collaboration-wrap {
      background-size: 28.35rem 12.9rem !important;
      background-position: calc(100% + 10.5rem) calc(100% + 2.4rem) !important;
    }
    .solution-banner {
      h2 {
        font-size: 0.6rem;
        font-weight: bold;
        text-align: center;
        padding-left: 0;
        padding-right: 0;
      }
      p {
        font-size: 0.24rem;
        margin-top: 0.2rem;
        text-align: center;
      }      
      button {
        margin-bottom: unset;
      }
      img {
        /* margin: 0 auto; */
        height: 6.2rem;
        transform: translate(-0.5rem, 0rem) scale(1);
      }
    }
  `};
`

export const HomeFtpBanner = ({ height = 600, isHome }) => (
  <Wrap height={height} isHome={isHome}>
    <SolutionBanner type='ftp' bg={HomeFerryImg} />
  </Wrap>
)
export const HomeFerryBanner = ({ height = 600, isHome }) => (
  <Wrap height={height} isHome={isHome}>
    <SolutionBanner type='ferry' bg={HomeFerryImg} />
  </Wrap>
)
export const HomeCollaborationBanner = ({ height = 600, isHome }) => (
  <Wrap height={height} isHome={isHome}>
    <SolutionBanner type='collaboration' bg={CollaborationImg} />
  </Wrap>
)
